<template>
  <div class="position-relative">
    <div class="legend" :id="'legend_' + id" :style="'--id:' + id"></div>
    <div class="position-relative chart-container">
      <canvas :id="id"></canvas>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js";
export default {
  props: {
    id: {
      required: true,
      type: String,
    },
    chartData: {
      required: true,
      type: Array,
    },
    label: {
      required: true,
      type: Array,
    },
    type: {
      required: false,
      type: String,
      default: "bar",
    },
    hideLegend: {
      required: false,
      type: Boolean,
    },

    xText: { required: false, type: String },
    text: { required: false, type: String },
  },

  mounted() {
    this.initChart();
  },
  methods: {
    addCommas(nStr) {
      var x, x1, x2;

      nStr += "";
      x = nStr.split(".");
      x1 = x[0];
      x2 = x.length > 1 ? "." + x[1] : "";
      var rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1)) {
        x1 = x1.replace(rgx, "$1" + "," + "$2");
      }
      return x1 + x2;
    },
    initChart() {
      let dataSum = 0;
      let maxData = [];
      this.chartData.forEach((sum) => {
        maxData.push(...sum.data.map((el) => el));
        dataSum += sum.data.reduce((a, b) => a + b, 0);
      });
      let suggestedMax = Math.max.apply(Math, maxData);
      var tick = 1;
      if (suggestedMax % 5 == 0) {
        tick = 5;
      } else if (suggestedMax % 3 == 0) tick = 3;
      else if (suggestedMax % 2 == 0) tick = 2;

      var ctx = document.getElementById(this.id);
      var that = this;
      var myChart = new Chart(ctx, {
        type: this.type,
        data: {
          labels: this.label,
          datasets: this.chartData,
        },
        options: {
          tooltips: {
            mode: "point",
            intersect: false,
            callbacks: {
              title: function (tooltipItem, data) {
                return `${data.datasets[tooltipItem[0].datasetIndex].label} (${
                  data.labels[tooltipItem[0].index]
                })`;
              },
              label: function (tooltipItem, data) {
                var datasetLabel =
                  data.datasets[tooltipItem.datasetIndex].label || "Other";
                return datasetLabel + ": " + that.addCommas(tooltipItem.value);
              },
            },
          },
          hover: {
            mode: "index",
            intersect: false,
          },
          responsive: true,
          maintainAspectRatio: false,

          interaction: {
            mode: "index",
          },
          legend: {
            display: !this.hideLegend,
            align: "center",
            position: "bottom",
            labels: {
              fontSize: 10,
              boxWidth: 12,
              boxHeight: 12,
              fontFamily: "Kanit-Regular",
              fontColor: "black",
            },
          },

          scales: {
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  fontStyle: "bold",
                  labelString: this.text || "",
                  fontFamily: "Kanit-Regular",
                },
                ticks: {
                  // autoSkip: true,
                  max: Math.round(suggestedMax) + tick,
                  beginAtZero: true,
                  suggestedMin: 0,
                  stepSize: tick,
                  callback: function (label, index, labels) {
                    return that.addCommas(label);
                  },
                },
              },
            ],
            xAxes: [
              {
                stacked: true,
                scaleLabel: {
                  display: true,
                  fontStyle: "bold",
                  labelString: this.xText || "",
                  fontFamily: "Kanit-Regular",
                },

                ticks: {
                  // autoSkip: true,
                  callback: function (value) {
                    return value.length > 20 ? value.split(" ")[0] : value;
                  },
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });
    },
  },
};
</script>

<style lang="scss">
.legend {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}

ul {
  padding-inline-start: 0px !important;
}
@media screen and (max-width: 600px) {
  .legend {
    bottom: 0;
    right: 50%;
    top: 100%;

    transform: translate(50%, -20px);
    ul {
      flex-direction: row !important;
      gap: 5px;
      li {
        margin-bottom: 0 !important;
      }
      span {
        font-size: 9px !important;
        width: max-content;
        max-width: 80px !important;
      }
    }
  }
}
.chart-container {
  height: 350px;
}
</style>
